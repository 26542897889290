import React from 'react'
import styled from 'styled-components'

export default styled(({ className, title }) => {
  return (
    <div className={className}>
      <h1>{title}</h1>
      <div className='technologies-icons'>
        <img
          className='technology__image'
          src='/images/react-icon.png'
          alt='react.js icon'
          />
          <img
          className='technology__image'
          src='/images/gatsby-icon.png'
          alt='gatsby.js icon'
          />
          <img
          className='technology__image'
          src='/images/node-icon.png'
          alt='node.js icon'
          />
          <img
          className='technology__image'
          src='/images/git-icon.png'
          alt='git icon'
          />
      </div>
    </div>
  )
})`
  width: 100%;
  .technologies-icons {
    display: flex;
    justify-content: space-between;
    @media(max-width:812px) {
      flex-direction: column;
    }
  }
`
