import React, { useContext } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa"
import siteConfig from "../../data/siteConfig"
import loadable from "@loadable/component"
import Hero from "../components/hero"
import Wrapper from "../components/wrapper"
import About from "../components/about"
import Skills from "../components/skills"
import Timeline from "../components/timeline"
import Repositories from "../components/repositories"
import { GlobalStateContext } from "../components/context"
import SEO from "../components/SEO"

const Layout = loadable(() => import("../components/layout"))

const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
  height: 2px;
  opacity: 0.4;
  background-color: ${({ theme }) => theme.colors.separatorColor};
`
const StyledWrapper = styled(Wrapper)`
  border: none;
  background-color: ${({ theme }) => theme.colors.wrapperBackground};
  color: ${({ theme }) => theme.colors.wrapperTextColor};
  div > div > div * {
    color: ${({ theme }) => theme.colors.wrapperTextColor};
  }
`

const Home = ({ className, location, theme }) => {
  const state = useContext(GlobalStateContext)
  const title = ""
  const SEOtitle = state.language === "ptBR" ? "Início" : "Index"
  const SEOImage = siteConfig.siteImage
  const { keywords } = siteConfig

  return (
    <>
    <SEO
    image={SEOImage}
    title={SEOtitle}
    keywords={keywords}
    pathname={location.pathname}
    article
    />
    <Layout location={location.pathname}>
      <Hero heroImg={siteConfig.siteCover} title={title} />
      <StyledWrapper className={className}>
        <Container className="page-content" fluid>
          <Row>
            <Col xs={4} sm={4} className="avatar">
              <div className="avatar__cropper">
                <img
                  className="avatar__image"
                  src="/images/avatar.jpeg"
                  alt="user avatar"
                />
              </div>
              <div className="social">
                {siteConfig.social.github && (
                  <a
                    className="social-link github"
                    href={siteConfig.social.github}
                  >
                    <FaGithub className="social-icon" size="32" />
                  </a>
                )}
                {siteConfig.social.linkedin && (
                  <a
                    className="social-link linkedin"
                    href={siteConfig.social.linkedin}
                  >
                    <FaLinkedin className="social-icon" size="32" />
                  </a>
                )}
                {siteConfig.social.email && (
                  <a
                    className="social-link email"
                    href={`mailto:${siteConfig.social.email}`}
                  >
                    <FaEnvelope className="social-icon" size="32" />
                  </a>
                )}
              </div>
            </Col>
            <Col xs={4} sm={4}>
              <About
                title={siteConfig.siteTitle[state.language]}
                text={siteConfig.authorDescription[state.language]}
              />
            </Col>
          </Row>
          <Separator />
          <Row>
            <Col className="technology" xs={4} sm={8}>
              <Skills
                title={
                  state.language === "ptBR" ? "Tecnologias" : "Technologies"
                }
              />
            </Col>
          </Row>
          <Separator />
          <Timeline
            title={state.language === "ptBR" ? "Experiência" : "Experience"}
            theme={theme}
          />
          <Separator />
          <Repositories
            title={
              state.language === "ptBR"
                ? "Últimos repositórios no Github"
                : "Latest repositories on Github"
            }
          />
        </Container>
      </StyledWrapper>
    </Layout>
    </>
  )
}

export default styled(Home)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }
  .avatar {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
  }
  .avatar__cropper {
    width:200px;
    height:200px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 24px;
  }
  .avatar__image {
    height: 100%
    display: inline;
    box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.75);
    width: auto;
  }
  .technology {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
  }
  .technology__image {
    overflow: auto;
    max-height: 4rem;
    object-fit: scale-down;
    margin: 0 24px 24px;
    background-color: transparent;
    @media(max-width:812px) {
      object-fit: scale-down;
      max-height: 5rem;
    }
  }
  .social {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .social-link {
    padding: 8px;
    color: #555;
  }
  a.social-link.twitter:hover {
    color: #1da1f2;
  }
  a.social-link.github:hover {
    color: #24292e;
  }
  a.social-link.linkedin:hover {
    color: #0077B5;
  }
  a.social-link.email:hover {
    color: #c23a2b;
  }
`
